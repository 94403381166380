/* eslint-disable @typescript-eslint/no-unused-vars */
import {App, Plugin} from "vue";

type PublicIdOrObjectWithIt = undefined | string | {
    public_id: string;
    [key: string]: unknown;
}

export interface CdnHelper {
    /* You can pass public id as string or object that contains public_id prop */
    url(image: PublicIdOrObjectWithIt, params?: Record<string, string>): string,
    /* You can pass public id as string or object that contains public_id prop */
    bgUrl(image: PublicIdOrObjectWithIt, options?: Record<string, string>): string,
    newUrl(image: PublicIdOrObjectWithIt, params?: Record<string, string>): string;
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $cld: CdnHelper
    }
}

export function cdnUrl(image, params?) {
    if (!image) return "";

    if (!params) params = {};

    const {
        quality,
        fetch_format,
        resource_type,
        transformation: transformation,
        ...otherParams
    } = params; // we won't use quality, fetch_format and resource_type

    let parsedTransformation = {};
    if (transformation) parsedTransformation = transformation[0];
    const {crop, ...curatedTransformation} = parsedTransformation;

    params = {
        format: "auto",
        ...curatedTransformation,
        ...otherParams,
    };

    let public_id;
    if (typeof image === "object") {
        const oldUrl = new URL(image.url as string);
        if (oldUrl.host === "media.songfinch.com") {
            const pathname = oldUrl.pathname.split("/");
            const publicId = pathname.pop() as string;
            const [filename, extension] = publicId.split(".");
            let version = pathname.splice(3, 1)[0];
            version = version.replace("v", "");
            pathname.push(filename);
            pathname.push(`${version}.${extension}`);
            public_id = "/res/songfinch" + pathname.join("/");
        } else {
            public_id = image.url as string;
        }
    } else {
        public_id = image;
    }

    const url = new URL(public_id, window.appSettings.filesCDN);
    url.search = new URLSearchParams(params).toString();
    return url.toString();
};

const CdnPlugin: Plugin = {
    install(app: App) {
        app.config.globalProperties.$cld = {
            url(image, params?) {
                if (!image) return "";
                params = {
                    quality: "auto",
                    fetch_format: "auto",
                    ...params
                };

                return this.newUrl(image, params);
            },
            bgUrl(image, params?) {
                return image ? `url(${this.url(image, params)})` : "";
            },
            newUrl(image, params?) {
                try {return cdnUrl(image, params);} catch (e) {return "";};
            }
        };

        app.provide("$cld", app.config.globalProperties.$cld);
    }
};

export default CdnPlugin;
